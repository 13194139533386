import axios from 'axios'
import { isEqualText, lowerCase, upperCase } from '@Utils/String'
import { isLengthBetween } from '@Components/utils/Utils'
import { exceptMenaCountry, menaCountryName, exceptCountryWithoutProduct } from '@Components/config'
import { enrollStore } from '@Stores/MainStore'

const currentPath = window.location.pathname.split('/')

const redirectToDefaultPath = async () => {
    return {
        success: false,
        path: `${window.location.origin}`,
        needReload: true
    }
}

const checkOnlineCountry = async () => {
    const response = await axios.get(`https://member-calls2.unicity.com/settings/country/enable`)
    return response
}

const process = (countryToCheck, countries) => {
    // sessionStorage.setItem('ushop-online-country', JSON.stringify(countries))

    let keyCheck = 'country'
    if (countryToCheck.length === 2) {
        keyCheck = 'shorter'
    } else if (countryToCheck.length === 3) {
        keyCheck = 'short'
    }

    let country

    if (exceptMenaCountry(upperCase(countryToCheck))) {
        country = countryToCheck
    } else {
        country = countries.find(item => isEqualText(item[keyCheck].replace(' ', ''), countryToCheck))
    }

    sessionStorage.removeItem('language')
    sessionStorage.removeItem('dictionary')
    localStorage.removeItem('langauge')

    if (country) {
        let countryToUse
        if (exceptMenaCountry(upperCase(countryToCheck))) {
            countryToUse = menaCountryName(upperCase(countryToCheck))
            enrollStore.isMenaCountry = true
        } else {
            countryToUse = lowerCase(country.country.replace(/\s/g, ''))
            if (country.maintenance_mode.maintenance_mode === true || country.country === 'LAOS') {
                sessionStorage.setItem(`maintenance-${countryToUse}`, JSON.stringify(country.maintenance_mode))
            }

            if (exceptCountryWithoutProduct(upperCase(country.shorter))){
                enrollStore.isWithoutProduct = true
            }
            
            enrollStore.isMenaCountry = false
        }

        if (isLengthBetween(countryToCheck, 2, 3)) {
            const paths = window.location.pathname.split('/')
            return {
                success: true,
                needReload: true,
                path: window.location.pathname.replace(paths[1], countryToUse)
            }
        } else {
            localStorage.setItem('country', countryToUse)

            return {
                success: true,
                path: null,
                needReload: false,
                country: country
            }
        }
    } else {
        return redirectToDefaultPath()
    }
}

export const checkCountry = async () => {
    const pathCountryName = currentPath[1]

    console.info('>> CHECKING ONLINE COUNTRY')
    // defined full country name or country code 2,3 alphabets
    if (pathCountryName.length > 0) {
        const a = checkOnlineCountry()
        return a.then(re => {
            if (re.data.success === true) {
                const checking = process(pathCountryName, re.data.data)
                return checking
            } else {
                return false
            }
        })
    } else {
        if (localStorage.getItem('country')) {
            window.location.href = `/${localStorage.getItem('country').replace(/\s/g, '')}`
        } else {
            return {
                success: true,
                path: '',
                needReload: true
            }
        }
    }
}
