// CSS
import './css/ushop-fonts.css';
import './renewCss.css';
//import './extrastrap.css';
import './antd.css';
import './styles/library.scss';
import './styles/mobiscroll.scss';
// //
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'react-image-lightbox/style.css';
//
import './components/shopping/ProductsLayout/product.css';
//import './components/RightTab/V2/TabSlide.styles.scss';
// MobX
import './stores/index';
import './stores/StoreProfile';
// Bowser
import './components/utils/Bowser';
// Configs
import './components/configs/index';

// import './components/utils/Utils';
// import './components/utils/Customer';

// import '@fortawesome/free-solid-svg-icons/index';
// import '@fortawesome/free-brands-svg-icons/index';
// import '@fortawesome/free-regular-svg-icons/index';