import React, { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { autorun, reaction, toJS, extendObservable } from 'mobx'
import ReactGA from 'react-ga'
import ReactPixel from 'react-facebook-pixel'
import dataCountry from './components/configs/json/country.json'
import { staticFooter, checkOutStore, store, storeManager, enrollStore, shopStore } from '@Stores/MainStore'
import { storeAutoShip } from '@Stores/StoreAutoShip'
import { Country } from '@Stores/StoreConfigCountry'
import { language } from '@Language'
import { appConfig } from '@Config'
import { getProvinceList, getProvinceListHK, getSiteVersion, getAwsStaticFooter, checkOutPeriod, getNationalityList } from './services/Network'
import ProductSource2 from './components/V2/ProductSource2'
import ProductSourceShareCart from './components/V2/ProductSourceShareCart'
import ProductSourceAutoship from './components/V2/ProductSourceAutoship'
import ProductSourceEnroll from './components/V2/ProductSourceEnroll'
import { getParameterByName, isProduction, checkHost, getDevelopmentID } from '@GlobalHelpers'
import { getQueryString } from '@Configs/ConfigsHeader'

import AclWrapper from '@ACL/AclWrapper'
import Redirect from './components/Redirect'

import SplashProductSource from '@Components/Splash/SplashProductSource'
import StoreSplash from '@Stores/StoreSplash'

import EnrollFactory from '@Components/enroll/lib/EnrollFactory'
import { storeBowser } from '@Stores/StoreBowser'
import { useBowser } from '@Utils/Bowser'
import { updateTranslation } from '@Utils/Translator'
import { getCustomerEntryPeriod, getCustomerID } from '@Utils/Customer'
import { isSomething, isNothing, isLength, isUndefined, defaults, someOf } from '@Utils/Utils'
import { logn, loge, logi, logtime } from '@Utils/PikaLog'
import { lowerCase, upperCase } from '@Utils/String'
import Raven from '@Raven'
import get from 'lodash/get'
import set from 'lodash/set'
import first from 'lodash/first'
import last from 'lodash/last'
import keys from 'lodash/keys'

import { configResponsive } from 'ahooks'
import mobiscroll from '@mobiscroll/react'
import '@mobiscroll/react/dist/css/mobiscroll.react.scss'
import TrackingEvents from '@Services/Tracker/TrackingEvents'
import StoreCountry from '@Stores/StoreCountry'

mobiscroll.settings = {
    theme: 'ios',
    themeVariant: 'light'
}

const App = observer(props => {
    // * -----------------------
    // * START -> Bowser
    const detector = useBowser()

    useEffect(() => {
        if (detector) {
            storeBowser.model = detector.model
            storeBowser.type = detector.type
            storeBowser.os = detector.os
        }
    }, [detector])
    // * END <- Bowser
    // * -----------------------

    useEffect(() => {
        if (/australia\/splash/.test(window.location.href)) {
            const memberId = getParameterByName('member_id')
            let queryString = ""
            if (memberId) {
                queryString = `?member_id=${memberId}`
            }

            window.location.replace(`https://ushop-feelgreat.unicity.com/australia${queryString}`)
        }

        logn(`Host: [${checkHost()}] | DevID: [${defaults(getDevelopmentID(), 'None')}]`)

        setTimeout(() => {
            TrackingEvents.Commit(StoreCountry.CountryLowerCase())
            // * END -> Google Analytics

            // * START -> Facebook Pixel
            const options = {
                autoConfig: true,
                debug: true
            }

            ReactPixel.init(appConfig.FBPixelCode[StoreCountry.CountryLowerCase()], {}, options)
            ReactPixel.pageView()
        }, 100)

        // * END <- Facebook Pixel

        // ** Start Initiate startup API **
        startInitiateAPI()

        // ** Delared resposive breakpoints.
        configResponsive({
            xs: 0,
            sm: 576,
            md: 768,
            lg: 992,
            xl: 1200,
            xxl: 1600
        })

        // country data
        store.countryData = dataCountry

        checkOutPeriod(
            (response, success) => {
                if (/edit/.test(window.location.href) && /autoship/.test(window.location.href)) {
                    storeAutoShip.periodListTrue = response.autoshipPeriodJP4Edit
                } else {
                    storeAutoShip.periodListTrue = response.autoshipPeriod
                }
                enrollStore.enrollData.period = response.entryPeriod
                EnrollFactory.EnrollData.period = response.entryPeriod
                StoreSplash.Period = response.entryPeriod
                store.entryPeriod = response.entryPeriod
            },
            {
                country: (Country.getCode2() === 'MENA') ? 'BH' : Country.getCode2(),
                joinPeriod: getCustomerEntryPeriod(),
                system: 'AO'
            }
        )

        autorun(r => {
            if (storeManager.isFooterStaticDone === true) {
                storeManager.isLocalStorageAPI = true
                r.dispose()
            }
        })

        autorun(r => {
            if (language.isInitialized === true && storeManager.isLocalStorageAPI === true && (enrollStore.enrollProductSource.length > 0 || shopStore.productDataSource.length > 0 || StoreSplash.IsSplashReady === true || enrollStore.isMenaCountry === true || enrollStore.isWithoutProduct === true || /wait-payment/.test(window.location.href) || /not-found/.test(window.location.href))) {
                storeManager.isReadyToDisplayHome = true
                r.dispose()
            }
            // console.log('language.isInitialized:'+language.isInitialized)
            // console.log('storeManager.isLocalStorageAPI:'+storeManager.isLocalStorageAPI)
            // console.log('OR enrollStore.enrollProductSource.length:'+enrollStore.enrollProductSource.length)
            // console.log('OR shopStore.productDataSource.length:'+shopStore.productDataSource.length)
            // console.log('OR StoreSplash.IsSplashReady:'+StoreSplash.IsSplashReady)
            // console.log('OR enrollStore.isMenaCountry:'+enrollStore.isMenaCountry)
            // console.log('OR enrollStore.isWithoutProduct:'+enrollStore.isWithoutProduct)

            // storeManager.isReadyToDisplayHome = true
            // r.dispose()
        })
        
    }, [])

    /** Start initiate all basic need APIs. */
    const startInitiateAPI = () => {
        logtime('Total Web Load Time').start()

        // * [URL Token and Href]
        const token = getParameterByName('token')
        const href = getParameterByName('href')
        if (token && href) {
            // override customer href and token if come from AO
            const customerHref = `https://hydra.unicity.net/v5a/customers/${href}`
            localStorage.setItem('customerHref', customerHref)
            localStorage.setItem('customerToken', token)
            localStorage.setItem('user-href', customerHref)
            localStorage.setItem('user-token', token)
            logn(`Use token and href from url.\nToken: ${token}\nHref: ${href}`)
        }

        // TODO: later check if this condition is deprecated
        if (sessionStorage.getItem('dtoken')) {
            let dtoken = JSON.parse(sessionStorage.getItem('dtoken'))
            checkOutStore.dToken = dtoken
        }

        // ** Check the exists of localStorage.

        // ** [API] Get current site version from database **
        const environment = isProduction() ? '' : '-DEV'
        const countrySource = lowerCase(`USHOP-LINK-${Country.getCode3()}`)
        getSiteVersion(countrySource, null, response => {
            setSiteVersion(countrySource, response)
        })

        // ** [API] Get all languages dictionary **
        loadLanguagesDictionary()

        // // ** [API] Get Footer V2 **
        // if (localFooter) {
        //     const dataFooter = JSON.parse(localFooter)
        //     if (dataFooter.market === Country.getCode2()) {
        //         store.footer = dataFooter
        //         storeManager.isFooterDone = true
        //     }
        // }

        // // ** [API] Get Static Footer **
        // if (localStaticFooter) {
        //     setStaticFooter(JSON.parse(localStaticFooter))
        //     storeManager.isFooterStaticDone = true
        // }
        // logtime('Update Footer Static').start()
        getAwsStaticFooter(
            (res, status) => {
                if (status) {
                    if (res.success === true) {
                        setStaticFooter(res.data.footer)
                        sessionStorage.removeItem(`static-footer-${Country.getCode3(true)}`)
                        sessionStorage.setItem(`static-footer-${Country.getCode3(true)}`, JSON.stringify(res.data.footer))
                        storeManager.isFooterStaticDone = true
                        // update language setting if footer completed after dictionary.
                        if (isLength(language.dictionaries) && !sessionStorage.getItem('dictionary')) {
                            updateTranslation(language.current)
                        }
                    }
                    // logtime('Update Footer Static').end()
                }
            },
            {
                strData: JSON.stringify({
                    source: 'UNISHOP-WEB-' + appConfig.countryCode[appConfig.country],
                    market: Country.getCode2()
                })
            }
        )

        // ** [API] Get provinces list for Thailand **
        if (Country.isThailand()) {
            getProvinceList((response, status) => {
                if (status) {
                    store.listProvinces = response.data
                }
            })
        }

        // ** [API] Get provinces list for Thailand **
        if (Country.isCambodia()) {
            getProvinceList((response, status) => {
                if (status) {
                    store.listProvinces = response.data
                }
            })
        }
        if (Country.isTaiwan()) {
            getProvinceList((response, status) => {
                if (status) {
                    store.listProvinces = response.data
                }
            })
        }

        // ** [API] Get provinces list for Thailand **
        if (Country.isMena()) {
            getNationalityList((response, status) => {
                if (status) {
                    store.listNationalitys = response.items
                }
            })
        }

        // if (Country.isThailand()) {
        //   language.current = 'TH'
        // } else if (Country.isTaiwan()) {
        //   language.current = 'TW'
        // }

        // ** [API] Get provinces list for Hongkong **
        if (Country.isHongKong()) {
            if (!localStorage.getItem('provinces-hk')) {
                getProvinceListHK((res, status) => {
                    if (status) {
                        res.map((v, k) => {
                            localStorage.setItem('provinces-hk', JSON.stringify(res))
                        })
                    }
                })
            } else {
                store.listProvinces = JSON.parse(localStorage.getItem('provinces-hk'))
            }
        }

        // ** Do this after all province API done
        if (isNothing(store.listProvinces) === false) {
            store.listProvinces.map(province => {
                const englishTitle = defaults(province['PROVINCE_NAME_ENG'], '').trim()
                const nativeTitle = defaults(province['PROVINCE_NAME'], '').trim()
                Object.assign(store.listProvincesNative, {
                    [englishTitle]: nativeTitle
                })
                Object.assign(store.listProvincesEnglish, {
                    [nativeTitle]: englishTitle
                })
                return null
            })
        }
    }

    /** Set versioning number from database. Clear **localStorage** when it's changed. */
    const setSiteVersion = (countrySource, response) => {
        const result = JSON.parse(response)
        if (result.success === 'yes') {
            const targets = result.target
            const version = result.version
            const localStorageTag = countrySource
            const currentUserID = getCustomerID()
            // a function for force reload and clear cache
            const forceReload = newVersion => {
                localStorage.clear()
                sessionStorage.clear()
                if (isNothing(newVersion) === false) {
                    localStorage.setItem(localStorageTag, newVersion)
                }
                window.location.reload(true)
                throw ': Force reload is required, please wait.'
            }
            // a function for check global versioning
            const checkGlobalReset = () => {
                logi('[Versioning] > Check for global versioning.')
                if (isNothing(localStorage.getItem(localStorageTag))) {
                    if (isNothing(result.source) === false) {
                        localStorage.setItem(localStorageTag, version)
                    }
                } else {
                    if (isNothing(result.source) === false) {
                        if (localStorage.getItem(localStorageTag) !== version) {
                            forceReload(version)
                        }
                    }
                }
            }

            logi('[Versioning] > ID:', currentUserID, '| Version:', version, '| Target:', targets)

            // api return targets versioning
            if (isNothing(targets) === false) {
                // user is logged in
                if (isUndefined(currentUserID) === false) {
                    const listTargets = targets.split(',')
                    listTargets.map(userID => {
                        // matched id
                        if (`${userID}` === `${currentUserID}`) {
                            logi('[Versioning] > Found target versioning:', `${userID}<U-V>${currentUserID}`)
                            // send target back to api
                            getSiteVersion(countrySource, currentUserID, response => {
                                const result = JSON.parse(response)
                                if (result.success === 'yes') {
                                    // ready to reload
                                    forceReload()
                                } else {
                                    checkGlobalReset()
                                }
                            })
                        }
                    })
                } else {
                    // no user logged in, check global version
                    checkGlobalReset()
                }
            } else {
                // no targets versioning, check global version
                checkGlobalReset()
            }
        }
    }

    /** Set static footer to MobX observable object. */
    const setStaticFooter = footerData => {
        staticFooter.footerApp = footerData.app
        staticFooter.country = footerData.country
        staticFooter.footerContact = footerData.contact
        staticFooter.footerHours = footerData.hours
        staticFooter.footerSocial = footerData.social
        staticFooter.footerQuickLink = footerData.quick_link
        staticFooter.footerHelp = footerData.help
        staticFooter.footerGeneral = footerData.general
        staticFooter.footerFeedBack = footerData.feedback
        staticFooter.footerDisclaimer = footerData.disclaimer
        staticFooter.footerBanner = footerData.banner
        staticFooter.footerPromotion = footerData.promotion
        staticFooter.footerVideo = footerData.video
        staticFooter.footerOrder = footerData.order
        staticFooter.footerTextTitle = footerData.title_product_section
        staticFooter.footerMessenger = footerData.messenger
        staticFooter.footerMainPic = footerData.main_pic
        staticFooter.footerDisplaySections = footerData.display_sections
        staticFooter.footerTCOrder = footerData.term_and_condition_order
        staticFooter.footerTCEnroll = footerData.term_and_condition_enroll
        staticFooter.footerMisc.ushopEnrollBirthday = footerData.ushop_enroll_birthday
        staticFooter.footerEnrollWithAutoShip = footerData.enroll_with_autoship
    }

    /** Set static footer to MobX observable object. */
    const loadLanguagesDictionary = () => {
        // storeAPI.MainApiStartLoad.language = true
        const languagesList = language.getUsageList()
        logn('🌐 Connecting with Language:', toJS(languagesList))

        // 1. Check the primary language first
        let primeLanguageCode = language.primary
        if (isSomething(languagesList)) {
            primeLanguageCode = first(languagesList)
        } else {
            primeLanguageCode = 'EN' // handled null error
            loge('🌐 Languages list is empty, set English by default.')
        }
        // set primary language to store
        language.primary = primeLanguageCode

        // NOTE: should improved this later (does NOT support multiple native language).
        if (primeLanguageCode !== 'EN') {
            language.native = primeLanguageCode
        } else {
            language.native = last(languagesList)
        }
        logn(`🌐 Language: Native [${language.native}] | Primary [${language.primary}]`)

        const forceLanguageCode = upperCase(getQueryString().lang)

        if (isSomething(forceLanguageCode) && someOf(forceLanguageCode, languagesList)) {
            logn(`🌐 Param language [${forceLanguageCode}].`)
            language.current = forceLanguageCode
        } else {
            logn(`🌐 Param language could not be found, use primary instead [${primeLanguageCode}].`)
            language.current = primeLanguageCode
        }

        // 2. Get all dictionary from API
        let resultLanguagesList = ''
        if (Array.isArray(languagesList)) {
            if (languagesList.length === 0) throw 'languagesList should not be empty.'
            if (languagesList.length > 1) {
                languagesList.forEach(lang => {
                    resultLanguagesList = resultLanguagesList.concat(lang, ',')
                })
                resultLanguagesList = resultLanguagesList.substring(0, resultLanguagesList.length - 1)
            } else {
                resultLanguagesList = languagesList[0]
            }
        } else {
            resultLanguagesList = languagesList
        }

        // if local storage for dictionary not exists, let's wait while loading from API
        language.currentRequestAttempt = 0
        requestDictionary(resultLanguagesList)
        
    }

    const requestDictionary = languageList => {
        Raven.getTranslations(languageList)
            .then(response => {
                const createDictionary = rawDictionary => {
                    const temp = {}
                    keys(rawDictionary).map(label => {
                        set(temp, label, get(rawDictionary, label))
                    })
                    return temp
                }

                // set all dictionary to store
                language.dictionaries = {}
                keys(response).map(languageCode => {
                    extendObservable(language.dictionaries, {
                        [languageCode]: createDictionary(response[languageCode])
                    })
                })
                language.isInitialized = true

                // update language setting if footer is ready.
                if (storeManager.isFooterStaticDone) {
                    updateTranslation(language.current)
                }
            })
            .catch(() => {
                if (language.currentRequestAttempt < language.maxRequestAttempt) {
                    language.currentRequestAttempt++
                    requestDictionary()
                } else {
                    language.currentRequestAttempt = 0
                }
            })
    }

    return (
        <>
            {(() => {
                if (storeManager.isReadyToDisplayHome === false) {
                    if (!/wait-payment/.test(window.location.href) || !/not-found/.test(window.location.href)) {
                        return (
                            <>
                                <Redirect />
                                {!/renewal/.test(window.location.pathname) &&
                                    !/autoship/.test(window.location.pathname) &&
                                    !/enroll/.test(window.location.pathname) &&
                                    !/social/.test(window.location.pathname) &&
                                    !/splash/.test(window.location.pathname) &&
                                    !/test-page/.test(window.location.pathname) && <ProductSourceShareCart />}

                                {/renewal/.test(window.location.pathname) && <ProductSource2 />}
                                {/enroll|test-page/.test(window.location.pathname) && <ProductSourceEnroll />}
                                {/social/.test(window.location.pathname) && <ProductSource2 allow="social" />}

                                {/autoship/.test(window.location.pathname) && <ProductSourceAutoship />}
                                {/splash+?/.test(window.location.pathname) && (
                                    <>
                                        <SplashProductSource />
                                    </>
                                )}
                            </>
                        )
                    }
                } else {
                    return <AclWrapper />
                }
            })()}
        </>
    )
})

export default App
